import React from 'react'
import SiteMetadata from '../SiteMetadata'
import PhoneStyles from '../../styles/Phone.module.sass'

/**
 * Display a phone number link
 *
 * @param {string} class
 * @param {string} text
 */
const Phone = props => {
  const { phone } = SiteMetadata()

  return (
    <a
      href={`tel:${phone}`}
      className={`phone ${props.class || ''} ${PhoneStyles.phone}`}
    >
      {props.text || `${phone}`}
    </a>
  )
}

export default Phone

/**
 * Create Netlify CMS Editor Component
 */
const PhoneEditorComponent = {
  // Internal id of the component
  id: 'phone',
  // Visible label
  label: 'Phone',
  // Fields the user need to fill out when adding an instance of the component
  fields: [
    { name: 'text', label: 'Text', widget: 'string', default: 'Call Us' },
    {
      name: 'class',
      label: 'Class',
      widget: 'string',
      required: false,
      default: '',
    },
  ],
  // Pattern to identify a block as being an instance of this component
  pattern: /<Phone text="(.*)" class="(.*)" \/>/,
  // Function to extract data elements from the regexp match
  fromBlock: function(match) {
    return {
      text: match[1],
      class: match[2],
    }
  },
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview: function(obj) {
    return <Phone text={obj.text} class={obj.class} />
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return '<Phone text="' + obj.text + '" class="' + obj.class + '" />'
  },
}

export { PhoneEditorComponent }
