import React from 'react'
import { Link } from 'gatsby'

/**
 * Display a button
 *
 * @param {string} url
 * @param {string} text
 * @param {string} class
 * @param {boolean} external Default: false
 */
const Button = props => {
  if (props.external === true || props.external === 'true') {
    // External Link
    return (
      <a
        href={props.url || '#'}
        className={`button is-link ${props.class || ''}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.text || 'Get a Quote Today'}
      </a>
    )
  } else {
    // Internal Link
    return (
      <Link
        to={props.url || '/contact/'}
        className={`button is-link ${props.class || ''}`}
      >
        {props.text || 'Get a Quote Today'}
      </Link>
    )
  }
}

export default Button

/**
 * Create Netlify CMS Editor Component
 */
const ButtonEditorComponent = {
  // Internal id of the component
  id: 'button',
  // Visible label
  label: 'Button',
  // Fields the user need to fill out when adding an instance of the component
  fields: [
    { name: 'url', label: 'URL', widget: 'string', default: '/contact/' },
    { name: 'text', label: 'Text', widget: 'string', default: 'Get a Quote Today' },
    {
      name: 'class',
      label: 'Class',
      widget: 'string',
      required: false,
      default: '',
    },
    {
      name: 'external',
      label: 'Is External?',
      widget: 'boolean',
      required: false,
      default: false,
    },
  ],
  // Pattern to identify a block as being an instance of this component
  pattern: /<Button url="(.*)" text="(.*)" class="(.*)" external="(.*)" \/>/,
  // Function to extract data elements from the regexp match
  fromBlock: function(match) {
    return {
      url: match[1],
      text: match[2],
      class: match[3],
      external: match[4],
    }
  },
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview: function(obj) {
    return (
      <Button
        url={obj.url}
        text={obj.text}
        class={obj.class}
        external={obj.external}
      />
    )
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return (
      '<Button url="' +
      obj.url +
      '" text="' +
      obj.text +
      '" class="' +
      obj.class +
      '" external="' +
      obj.external +
      '" />'
    )
  },
}

export { ButtonEditorComponent }
