import React from 'react'
import SiteMetadata from './SiteMetadata'
import Button from './shortcodes/Button'
import FloatingCTAStyles from '../styles/FloatingCTA.module.sass'

/**
 * Display a CTA with fixed position
 */
const FloatingCTA = () => {
  const { phone } = SiteMetadata()

  return (
    <div className={`floating-cta ${FloatingCTAStyles.floatingCTA}`}>
      <span className={FloatingCTAStyles.text}>
        Call us on {phone} or click below for a quote.
      </span>
      <Button
        class={`is-hidden-mobile is-primary ${FloatingCTAStyles.button}`}
        text="Get a Quote"
        url="/contact/"
      />
      <a
        className={`button is-link is-hidden-tablet ${FloatingCTAStyles.button}`}
        href={`tel:${phone}`}
      >
        CALL {phone}
      </a>
    </div>
  )
}

export default FloatingCTA
